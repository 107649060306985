/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.ocr-demo {
  padding-top: 108px;
  .content-container {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    margin-bottom: 150px;

    .content {
      max-width: 650px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .bg-contain {
      background-repeat: no-repeat;
      background-attachment: local;
      background-position: center;
      background-size: contain;
    }

    @include respond-to('max-min') {
      position: relative;
      flex-direction: column;
      padding: 0 15px;

      .bg-contain {
        max-width: 100%;
      }
    }

    .title {
      padding: 0;
      font-size: 50px;
      line-height: 80px;
      margin-bottom: 32px;
      font-weight: 700 !important;
    }
    .child-content {
      padding: 0;
      padding-top: 12px;
      font-size: 18px;
      line-height: 35px;
      font-weight: 500 !important;
      margin-bottom: 36px;
    }
  }

  .custom-container {
  }

  .scanner-body {
    min-height: calc(100vh - 240px);
    background: #ffffff;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0px 4px 48px 0px #b0b0b040;
  }

  .wrapper-input {
    max-width: 100%;
    height: 100%;

    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 33.89px;
      text-align: center;
      color: $black-color;
      margin-bottom: 14px;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: center;
      margin-bottom: 24px;
    }

    .form-upload {
      min-height: calc(100vh - 350px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-actions {
      width: 410px;
      max-width: 100%;
      margin: 0 auto;
    }

    .box-request-convert {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
  }

  .wrapper-result {
    height: calc(100vh - 360px);

    .result-label {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      text-align: left;
      margin-bottom: 18px;
    }

    .result-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      border-radius: 8px;
      border: 1px dashed #00000040;
      padding: 18px 35px 30px;
    }

    .loading-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      text-align: center;
      margin-bottom: 14px;
    }

    .loading-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: center;
      margin-bottom: 22px;
    }

    .result-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      color: $black-color;
      margin-bottom: 20px;
    }

    .result-content {
      width: 100%;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      white-space: pre-wrap;
    }

    .input-result .ant-input {
      width: 100%;
      padding: 0;
      border: none;
      box-shadow: none;
    }

    &.error {
      .result-box {
        border: 1px dashed $danger-color;
      }

      .result-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .result-title,
      .result-content {
        text-align: center;
      }
    }

    &.loading {
      height: calc(100vh - 300px);

      .result-box {
        justify-content: center;
      }
    }

    &.has-input {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 24px;

      .result-input,
      .result-box {
        flex: 1;
      }

      .box-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #0057e2;
        border-radius: 8px;
        background: #f8fbff;
        padding: 20px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .result-action-box {
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 24px;
  }

  .note-feature {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: $sub-black-color;
    margin-top: 8px;

    span {
      color: $danger-color;
    }
  }

  @include respond-to('max-min') {
    padding-top: 50px;

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 24px;
    }

    .scanner-body {
      min-height: auto;
      flex-direction: column;
      padding: 15px;
    }

    .wrapper-input {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      border: none;

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
      }

      .form-upload {
        min-height: auto;
      }
    }

    .wrapper-result.has-input {
      flex-direction: column;
      min-height: auto;
      height: auto;

      .result-input {
        overflow: auto;
      }

      .box-image {
        padding: 10px;
      }
    }
  }
}
