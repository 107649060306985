/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.dragger-upload-default {
  display: inline-block;
  width: 100%;
  max-width: 100%;

  &.ant-upload-wrapper .ant-upload-drag,
  &.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
  .ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
    background: #f8fbff;
    border: 1px dashed #0057e2;
    border-radius: 8px;
  }

  &.ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 24px;
  }

  .icon-upload {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-bottom: 16px;

    > img {
      width: 100px;
      height: 60px;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  .ant-upload-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;
    margin-bottom: 4px;
    color: $description-black-color !important;
  }

  .ant-upload-hint {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    margin-bottom: 16px;
    color: $description-black-color !important;

    &.or,
    &.or-webcam {
      margin-bottom: 8px;
    }

    &.or-webcam {
      color: $blue-color !important;
    }
  }

  .list-item-upload {
    margin-top: 14px;

    .upload-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #061d3f;
      margin-bottom: 12px;
    }

    .file-item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 8px;

      img {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }

      .file-name {
        max-width: calc(100% - 70px);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #061d3f;
      }

      .btn-remove-file {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}
