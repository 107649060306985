/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

@import 'base/reset';

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/font/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Abril Fatface';
  src: url('../assets/font/AbrilFatface-Regular.ttf') format('truetype');
}
body {
  // font-family: Noto Sans;
  font-family: 'Inter', sans-serif;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    // background: #0b1725;
    background: #062350;
  }

  &::-webkit-scrollbar-thumb {
    background: #37373780;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #58535380;
  }
}

.main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include respond-to('min-min') {
    width: 540px;
  }
  @include respond-to('min-medium') {
    width: 720px;
  }
  @include respond-to('min-large') {
    width: 960px;
  }
  @include respond-to('min-huge') {
    width: 1230px;
  }
}
.color-white {
  color: #fff;
  font-size: 20px;
}
.color-dark {
  color: #1b2531;
  font-size: 20px;
}

.bg-color1 {
  background-color: #1b2531;
}
.bg-color2 {
  background-color: #ffff;
}

.bg-color3 {
  background-color: #f5f7fa;
}

.test-bgcolor1 {
  background-color: rgba(29, 92, 104, 0.975);
}
.test-bgcolor2 {
  background-color: rgba(104, 29, 79, 0.975);
}
.test-bgcolor3 {
  background-color: rgba(104, 29, 32, 0.975);
}
.test-bgcolor4 {
  background-color: rgba(29, 54, 104, 0.975);
}
.test-bgcolor5 {
  background-color: rgba(104, 92, 29, 0.975);
}
.section-title {
  padding-bottom: 20px;
  padding-top: 45px;
  h1 {
    font-size: 40px;
    line-height: 54px;
    padding-bottom: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }
  img {
    // margin: 0 auto;
  }
  @include respond-to('max-min') {
    h1 {
      font-size: 24px;
      line-height: 34px;
      padding: 24px;
    }
  }
}
.banner-title {
  background: url('../assets/img/background-title.png') center center no-repeat;
  background-size: cover;
  height: 192px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
    font-size: 40px;
    line-height: 54px;
    padding-bottom: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  @include respond-to('max-min') {
    h1 {
      font-size: 24px;
      line-height: 34px;
      padding: 24px;
    }
  }
}
.overlay__blur {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // background: linear-gradient(180deg, #101b2a00 0%, #0f1c2c 92.62%);
  background: linear-gradient(180deg, #101b2a00 0%, #062350 92.62%);
}
