/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

// ----------start wrapper-------
.wrapper {
  font-family: 'Inter', sans-serif;
  //background-color: #1b2531;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: auto;
  min-height: 100vh;

  .sun-editor-editable {
    font-family: 'Inter', sans-serif;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}

// ------------end wrapper---------

.separating-block {
  height: 72px;
  background: $header-color;
}
.btn-back-top {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 400px;
  background: #3e4044;
  cursor: pointer;
  position: fixed;
  z-index: z('dropdown');
  bottom: 35px;
  right: 37px;
  @include respond-to('max-min') {
    width: 40px;
    height: 40px;
    right: 10px;
    svg {
      width: 15px;
    }
  }
}

hr.solid {
  border-top: 1px solid #eee;
}
