/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.ai-solutions-features {
  padding-top: 80px;
  padding-bottom: 80px;
  background: linear-gradient(180deg, #f1f2f6 0.14%, #ffffff 99.88%);

  .custom-container {
  }

  .title {
    font-size: 46px;
    font-weight: 700;
    line-height: 55.67px;
    text-align: center;
    color: $black-color;
    margin-bottom: 60px;
  }

  .list-content-items {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .wrapper-features-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 47px;
    background: #ffffff;
    box-shadow: 0px 4px 48px 0px #b0b0b040;
    padding: 32px 0;
    border-radius: 20px;

    .features-content {
      width: 518px;
      max-width: 100%;
      padding-left: 32px;
    }

    .features-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
      text-align: left;
      color: $black-color;
      margin-bottom: 16px;
    }

    .features-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $description-black-color;
      margin-bottom: 8px;
    }

    .other-list-items {
      margin-bottom: 24px;

      .other-item {
        display: flex;
        gap: 11px;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .label {
        min-width: 92px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        color: $black-color;
      }

      .value {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: $description-black-color;
      }
    }

    .feature-button {
      padding-left: 24px;
      padding-right: 24px;
    }

    .features-image {
      height: 300px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &:nth-child(even) {
      .features-image {
        order: 1;
      }

      .features-content {
        order: 2;
        padding-right: 32px;
        padding-left: 0;
      }
    }

    &.half {
      width: calc(50% - 20px);
      gap: 16px;

      .features-image {
        height: 398px;
      }

      .features-content {
        width: 300px;
      }

      &:nth-child(even) {
        .features-image {
          height: 398px;
          order: 1;
        }

        .features-content {
          width: 300px;
          order: 1;
          padding-right: 0;
          padding-left: 32px;
        }
      }
    }
  }

  @include respond-to('max-min') {
    padding-top: 32px;
    padding-bottom: 30px;

    .custom-container {
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 24px;
    }

    .list-content-items {
      gap: 20px;
    }

    .wrapper-features-content {
      flex-direction: column;
      gap: 10px;
      .features-content {
        padding: 0 15px;
      }
      .features-image {
        height: auto;
      }

      &:nth-child(even) {
        .features-content {
          padding: 0 15px;
          order: 1;
        }

        .features-image {
          order: 2;
        }
      }

      &.half {
        width: 100%;

        .features-content {
          width: 100%;
          padding: 0 15px;
        }
        .features-image {
          height: auto;
        }

        &:nth-child(even) {
          width: 100%;

          .features-content {
            width: 100%;
            padding: 0 15px;
          }
          .features-image {
            height: auto;
          }
        }
      }
    }
  }
}
