/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.wrapper-pdf-viewer {
  width: 100%;
  height: 100%;

  .toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
    background: #32363920;
    border-radius: 8px 8px 0 0;
  }

  .rpv-core__viewer {
    height: calc(100% - 34px) !important;
    border: 1px solid #32363920;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  @include respond-to('max-min') {
    aspect-ratio: 1 / 1.414;
  }
}
