/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.step-by-steps.face-recognition {
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(360deg, #f8faff 0.14%, #ffffff 99.88%);

  .custom-container {
  }

  .title {
    font-size: 46px;
    font-weight: 700;
    line-height: 55.67px;
    text-align: center;
    color: $black-color;
    margin-bottom: 27px;
  }

  .wrapper-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 47px;
    margin-bottom: 44px;

    .tab-item {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #9b9b9b;
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $blue-color;
        border-bottom: 2px solid $blue-color;
      }
    }
  }

  .wrapper-steps {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 48px;
  }

  .list-steps {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .tab-item {
      position: relative;
      min-height: 145px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      cursor: pointer;
    }

    .tab-icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .tab-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
      margin-bottom: 8px;
      color: $black-color;
    }

    .tab-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: $description-black-color;
      padding-bottom: 30px;
    }
  }

  .current-step-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-step {
    width: 100%;

    > img {
      width: 100%;
      object-fit: contain;
    }
  }

  .step-progress {
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px);

    .ant-progress-bg {
      height: 2px !important;
      background: $primary-color;
    }

    &.ant-progress.ant-progress-status-success .ant-progress-bg {
      background: $primary-color;
    }
    &.ant-progress .ant-progress-inner {
      background-color: #cfe1ff;
    }
  }

  @include respond-to('max-min') {
    padding-top: 32px;
    padding-bottom: 30px;

    .custom-container {
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 24px;
    }

    .wrapper-steps {
      flex-direction: column;
    }
  }
}
