/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.offline {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(360deg, #f5f7fa 0%, #daddfb 100%);
  background-image: url('./../../../../assets/img/offline/BG.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  visibility: hidden;
  z-index: -1;

  &.active {
    visibility: initial;
    z-index: 1;
  }

  .offline-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-top: 200px;
    z-index: 10;
  }

  .offline-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 36.31px;
    text-align: center;
    color: $black-color;
  }

  .offline-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: $description-black-color;
  }

  .offline-image {
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    bottom: 40px;
    justify-content: center;

    img {
      width: 920px;
      max-width: 100%;
      height: 428px;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
