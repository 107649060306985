/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.difference {
  padding-top: 50px;
  padding-bottom: 50px;

  .custom-container {
  }

  .wrapper-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 54px;
  }

  .title {
    font-size: 46px;
    font-weight: 700;
    line-height: 55.67px;
    text-align: left;
    color: $black-color;
    margin-bottom: 40px;
  }

  .main-image {
    width: 730px;
    max-width: 100%;

    > img {
      width: 100%;
      object-fit: contain;
    }
  }

  .wrapper-steps {
    margin-top: 70px;

    .list-steps {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 18px;
    }

    .step-item {
      position: relative;
      min-width: 226px;
      font-size: 18px;
      font-weight: 500;
      line-height: 21.78px;
      text-align: center;
      color: #08244f;
      padding: 16px 0;
      border-radius: 8px;
      border: 2px solid #b6d2ff;

      &::before {
        position: absolute;
        top: -25px;
        content: ' ';
        display: block;
        width: 150%;
        height: 1px;
        background-color: #b8c3ce;
        z-index: 5;
      }

      &:first-child::before {
        left: 50%;
      }

      &:last-child::before {
        right: 50%;
      }
    }

    .number-index {
      position: absolute;
      top: -36px;
      left: 50%;
      transform: translateX(-50%);
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      background: $primary-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      color: #fff;
      z-index: 10;
    }

    .list-steps.second {
      .step-item:last-child {
        flex: 1;

        &::before {
          width: 50%;
        }
      }
    }
  }

  @include respond-to('max-huge') {
    .wrapper-steps {
      .list-steps {
        flex-direction: column;
        padding-left: 40px;
      }

      .step-item {
        width: 100%;

        &::before {
          position: absolute;
          left: -30px;
          top: 50%;
          transform: translateY(-50%);
          content: ' ';
          display: block;
          height: calc(100% + 30px);
          width: 1px;
          background-color: #b8c3ce;
          z-index: 5;
        }

        &:first-child::before {
          top: calc(50% + 30px);
          left: -30px;
        }

        &:last-child::before {
          bottom: calc(50% - 30px);
          left: -30px;
          top: auto;
        }
      }

      .number-index {
        top: 50%;
        left: -40px;
        transform: translate(0, -50%);
      }

      .list-steps.second {
        .step-item:last-child::before {
          bottom: calc(100% - 20px);
          height: 40%;
          width: 1px;
        }
      }
    }
  }

  @include respond-to('max-min') {
    padding-top: 32px;
    padding-bottom: 30px;

    .custom-container {
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 24px;
    }

    .wrapper-title {
      flex-direction: column;
      gap: 20px;
    }
    .main-image {
      order: 1;
    }

    .wrapper-steps {
      margin-top: 32px;
    }

    .custom-switch {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
