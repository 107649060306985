/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.footer {
  color: #fff;

  .f-top {
    background-image: url('./../../../../assets/img/F-background.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;

    .custom-container {
      min-height: 650px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }

    .wrapper-contents {
      flex: 1;

      .title {
        font-size: 50px;
        font-weight: 600;
        line-height: 60.51px;
        text-align: left;
        margin-bottom: 18px;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        margin-bottom: 18px;
      }

      .list-offices {
        height: 300px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 16px 60px;
      }

      .wrapper-office {
        max-width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        padding: 6px;
        border-radius: 5px;
        cursor: pointer;

        &.active {
          background: #ffffff21;
        }
      }
    }

    .wrapper-maps {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .box-maps {
        width: 450px;
        max-width: 100%;
        padding: 24px 24px 31px 24px;
        border-radius: 20px;
        background: #ffffff54;
      }

      .office-name {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
        margin-bottom: 16px;
      }

      .office-info,
      .office-time-open {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1px;
        margin-bottom: 16px;

        &.office-info {
          padding-left: 16px;
        }
      }

      .office-time-open {
        display: flex;
        align-items: center;

        > img {
          width: 14px;
          margin-right: 8px;
        }

        > span.sub-time {
          margin-left: 20px;
        }
      }

      .map {
        iframe {
          width: 100%;
          height: 260px;
          border-radius: 10px;
        }
      }
    }
  }

  .f-bottom {
    background: #061d3f;
  }

  .wrapper-f-bottom {
    background: #061d3f;
    padding-top: 28px;
    padding-bottom: 28px;

    .main-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 50px;
    }

    .logo {
      > img {
        height: 85px;
      }
    }

    .social-network {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 32px;

      > img {
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
    }

    .copyright {
      border-top: 1px solid #ffffff33;

      > p {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin-top: 24px;
      }
    }
  }

  @include respond-to('max-huge') {
    .f-top .wrapper-contents .list-offices {
      height: auto;
    }
  }

  @include respond-to('max-large') {
    .f-top .custom-container {
      flex-direction: column;

      .list-offices {
        height: auto;
        margin-bottom: 20px;
      }
    }
  }

  @include respond-to('max-min') {
    .f-top {
      padding-top: 30px;
      padding-bottom: 30px;

      .wrapper-contents {
        .title {
          font-size: 26px;
          font-weight: 700;
          line-height: 31.47px;
        }

        .description {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
      }
    }

    .wrapper-f-bottom .main-content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      margin-bottom: 24px;
    }

    .wrapper-f-bottom .copyright > p {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
    }
  }
}
