/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.audio-input-speech-to-text {
  display: none;
}

#audio {
  .note {
    font-size: 14px;
    padding-top: 10px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #333333;
    font-style: oblique;
    .color-red {
      color: red;
    }
  }
  .custom-container {
    padding-top: 108px;
  }

  .audio-recorder {
    display: none;
  }

  .audio-speech2text {
    .record-body {
      min-height: calc(100vh - 240px);
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      gap: 24px;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 0px #0000001a;
      padding: 24px;
      border-radius: 20px;
    }

    .wrapper-input {
      flex: 1;
      max-width: 100%;
      padding-top: 32px;
      padding-bottom: 24px;
      padding-right: 50px;
      padding-left: 25px;
      border-right: 1px solid #e8eaed;

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 33.89px;
        text-align: center;
        color: $black-color;
        margin-bottom: 14px;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: center;
        margin-bottom: 14px;
      }

      .record-images {
        width: 100%;
        position: relative;

        .img-sound {
          width: 100%;
          height: auto;
        }

        .img-mic {
          width: 130px;
          height: 130px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }

      .time-record {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        text-align: center;
        color: #333333;
        margin-bottom: 16px;
      }

      .box-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        > hr {
          width: 100%;
          border-bottom: 1px solid #d9d9d9;
          margin-bottom: 16px;
        }

        .note-upload {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: center;
          margin-bottom: 16px;
        }

        .btn-upload,
        .btn-upload:hover {
          background: #e4edff;
          color: #636e80;
        }
      }
    }
  }

  @include respond-to('max-min') {
    .custom-container {
      padding-top: 50px;
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 24px;
    }

    .audio-speech2text .wrapper-input .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
    }

    .audio-speech2text .wrapper-input {
      border-right: none;
      border-bottom: 1px solid #e8eaed;
    }
  }
}

.wrapper-input {
  .disabled {
    opacity: 0.6;
  }
  .box-actions {
    .btn-default.btn-upload {
      border: none;
      border-radius: 20px;
    }
  }
}
.result-container {
  .wrapper-result {
    height: 100%;
    .result-box {
      height: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      .text-result {
        height: calc(100% - 60px);
        border-radius: 8px;
        // border: 1px dashed #00000040;
        .result-content {
          height: 100%;
          overflow: auto;
        }
        .input-result {
          height: 100%;
          .ant-input {
            width: 100%;
            padding: 0;
            border: none;
            box-shadow: none;
            height: 100%;
            border: 1px dashed #00000040;
            padding: 20px;
            min-height: 200px;
          }
        }
      }
      .text-note {
        opacity: 0.5;
        color: #636e80;
      }
      .result-action-box {
        display: flex;
        justify-content: end;
        padding-top: 20px;
      }
      .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .loading-box {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
        }

        .loading-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 19.36px;
          text-align: center;
          text-align: center;
          margin-bottom: 14px;
          color: #061d3f;
        }

        .loading-description {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: center;
          margin-bottom: 22px;
        }
      }
    }
  }
}
