/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.header {
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px 15px 0;
  z-index: z('header');

  &.sub-layout {
    height: 80px;
  }

  @include respond-to('max-min') {
    padding: 0;

    &.sub-layout {
      height: 50px;
    }

    .logo,
    .logo img {
      height: 30px;
    }
  }

  .navbar {
    background-color: $header-color;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    margin: 0 auto;
    border-radius: 100px;
    padding: 0 50px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);

    @include respond-to('max-min') {
      padding: 0 10px;
      height: 50px;
      border-radius: 0;
    }

    // @include respond-to('min-min') {
    //   max-width: 540px;
    // }
    // @include respond-to('min-medium') {
    //   max-width: 850px;
    // }
    // @include respond-to('min-large') {
    //   max-width: 960px;
    // }
    // @include respond-to('min-huge') {
    //   max-width: 1140px;
    // }

    .nav-list {
      @include respond-to('max-min') {
        display: none;
      }

      display: flex;
      align-items: stretch;
      gap: 20px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0px;
      }

      a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: center;
        color: $black-color;
        padding: 10px;
      }

      .header-dropdown-content {
        min-width: 260px;
        max-width: 300px;

        a {
          white-space: break-spaces;
          word-wrap: break-word;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          text-align: left;
          padding: 5px 10px;

          &.flag-item {
            display: flex;
          }
        }
        a:hover {
          color: #317af7;
        }
      }

      svg {
        display: inline;
        transition: transform 0.3s linear;
      }
    }

    .nav-list .language-icons.header-dropdown .header-dropdown-content {
      min-width: 160px;
      width: auto;
      right: -45px;
      left: auto;

      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
      }
    }
  }

  &.transparent {
    .navbar {
      background-color: #ffffff00;
      transition: all 0.2s linear;
      box-shadow: none;

      .nav-list {
        a {
          color: #fff;
        }
        svg path {
          stroke: #fff;
        }
      }
    }

    .navbar .nav-list .header-dropdown-content a {
      color: #1d2129;

      &:hover {
        color: #317af7;
      }
    }

    .header-menu svg path {
      stroke: #fff;
    }
  }

  .nav-list li.language-icons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    .current-language {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
    }
  }
}

.header-dropdown {
  position: relative;

  a.show {
    line-height: 72px;
  }
  &:hover {
    svg {
      transform: rotate(180deg);
    }
    .header-dropdown-content {
      transform: translateY(0);
      max-height: 100vh;
      opacity: 1;
      z-index: 2;
    }
  }
}

.header-dropdown-content {
  font-weight: 500;
  font-size: 12px;
  opacity: 0;
  top: 100%;
  left: -26px;
  background-color: $header-color;
  min-width: 151px;
  max-height: 0;
  overflow: hidden;
  text-align: left;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: -1;
  transform: translateY(-20px);
  transition: all 0.2s linear;
  border-radius: 16px;
  border: 1px solid #edf1ff;
  padding: 12px;

  a {
    display: block;
    padding: 10px;
    white-space: nowrap;

    &:hover {
      background: #edf1ff;
      border-radius: 8px;
    }
  }

  a.flag-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    > img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}
.header-dropdown-content.active {
  transform: translateY(0);
  opacity: 1;
  z-index: 2;
}

.header-menu {
  margin-right: 15px;
  display: none;
  @include respond-to('max-min') {
    display: block;
  }
}
.nav-mobile {
  width: 100%;
  padding: 15px;
  background: white;
  color: #1d2129;
  transform: translateX(-200%);
  opacity: 0;
  transition: transform 0.4s ease-out, opacity 0.6s ease-out;

  .nav-list {
    line-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    svg {
      transform: rotate(0deg);
    }
  }

  .header-dropdown-content a {
    line-height: 20px;
  }

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-item {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: visibility 0s, opacity 0.5s linear, max-height 0.6s ease;
    padding-left: 15px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;

    li {
      border-bottom: 1px solid #2f3a4b;
    }
  }
  .nav-list.active {
    .dropdown-item {
      max-height: 500px;
      opacity: 1;
    }
    svg {
      transform: rotate(180deg);
      transition: transform 0.5s;
    }
  }
}
.nav-mobile.active {
  transform: translateX(0);
  opacity: 1;
}
