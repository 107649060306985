/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.game_metaverse_introduce {
  padding-top: 60px;
  padding-bottom: 60px;
  background: linear-gradient(180deg, #f8faff 0.14%, #ffffff 99.88%);

  .custom-container {
  }

  .title-description {
    margin-bottom: 60px;

    .title {
      font-size: 50px;
      font-weight: 600;
      line-height: 60.51px;
      text-align: center;
      color: $black-color;
      margin-bottom: 24px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: $description-black-color;
      margin-bottom: 40px;
    }

    .image-intro {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 720px;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  .feature {
  }

  .feature-title {
    font-size: 50px;
    font-weight: 600;
    line-height: 60.51px;
    text-align: center;
    color: $black-color;
    margin-bottom: 60px;
  }

  .introduce-list-feature {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 22px;

    .image {
      width: 356px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .left,
    .right {
      width: 400px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;

      .feature-item {
        flex: 1;
      }
    }

    .feature-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24.2px;
      text-align: left;
      color: $black-color;
      margin-bottom: 8px;
    }

    .feature-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $description-black-color;
    }
  }

  @include respond-to('max-min') {
    padding-top: 32px;
    padding-bottom: 30px;

    .custom-container {
    }

    .title-description {
      margin-bottom: 50px;

      .title {
        font-size: 26px;
        font-weight: 700;
        line-height: 31.47px;
        text-align: center;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .feature-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 16px;
    }

    .introduce-list-feature {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
      }

      .image {
        margin: 0 auto;
      }
    }
  }
}
