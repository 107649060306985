/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

#demo-face-recognition {
  .scanner-body {
    min-height: calc(100vh - 240px);
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 30px;
    box-shadow: 0px 4px 48px 0px #b0b0b040;
  }

  .scanner-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 47px;
    margin-bottom: 38px;

    .tab-item {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #9b9b9b;
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $blue-color;
        border-bottom: 2px solid $blue-color;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .wrapper-input {
    max-width: 100%;
    height: 100%;

    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 33.89px;
      text-align: center;
      color: $black-color;
      margin-bottom: 14px;
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: center;
      margin-bottom: 24px;
    }

    .wrapper-error {
    }

    .form-upload {
      min-height: calc(100vh - 350px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-actions {
      width: 532px;
      max-width: 100%;
      margin: 0 auto;
    }

    .box-request-convert {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
  }

  .wrapper-tabs,
  .wrapper-result {
    position: relative;
    min-height: calc(100vh - 360px);
    border-radius: 8px;
    border: 1px dashed #00000040;

    &.hidden {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  .wrapper-image-info {
    min-height: calc(100vh - 360px);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 24px;
    padding: 24px;

    &.hidden {
      visibility: hidden;
      display: none;
    }

    .image-box {
      flex: 1;
      min-width: 392px;
      height: 100%;
    }

    .main-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px dashed #0057e2;
      background: #f8fbff;
      padding: 16px;
      overflow: hidden;

      .image {
        position: relative;
        width: 100%;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        canvas {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
        }
      }

      .btn-try-again {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
      }
    }

    .list-faces {
      display: flex;
      justify-content: center;
      align-content: center;
      gap: 8px;
      margin-top: 12px;
      flex-wrap: wrap;

      .face-item {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        background: #fff;
        border: 1px solid #00000040;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &.success,
        &.fail {
          position: relative;

          > img {
            opacity: 0.5;
          }

          > img.icon {
            opacity: 1;
            position: absolute;
            content: ' ';
            width: 18px;
            height: 18px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 9999px;
            overflow: hidden;
          }
        }

        &.success {
          border: 1px solid #3aa754;
        }

        &.fail {
          border: 1px solid $danger-color;
        }

        &.active,
        &:hover {
          border: 1px solid #216edc;
        }
      }
    }

    .line {
      width: 1px;
      min-height: 100%;
      background: #d9d9d9;
    }

    .info-box {
      min-width: 511px;
      max-width: 100%;

      > form {
        height: calc(100% - 30px);
      }
    }

    .wrapper-error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.hidden {
        display: none;
      }

      .massage-error {
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: center;
        color: $danger-color;
      }
    }

    .form-info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
    }

    .form-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #061d3f;
    }

    .form-body {
      max-height: 480px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        top: 0;
        right: -10px;
        width: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #bcbec0;
        border-radius: 9999px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #bcbec0;
      }

      .field-input {
        padding: 8px;

        .inline {
          margin-bottom: 0;
        }

        &:nth-child(odd) {
          background: #ffffff;
        }

        &:nth-child(even) {
          background: #f5f9f9;
        }
      }
    }

    .field-result {
      display: flex;
      justify-items: flex-start;
      align-items: flex-start;
      gap: 10px;
      padding: 8px;

      &:nth-child(odd) {
        background: #ffffff;
      }

      &:nth-child(even) {
        background: #f5f9f9;
      }

      .label {
        min-width: 150px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #061d3f;
        margin: 0;
      }

      .value {
        min-height: 32px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.002em;
        color: #383838;
        white-space: break-spaces;
      }
    }
  }

  .wrapper-result {
    padding: 18px 35px 30px;

    .result-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
    }

    .result-label {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      text-align: left;
      margin-bottom: 18px;
    }

    .result-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      color: $black-color;
      margin-bottom: 30px;
    }

    .result-content {
      width: 100%;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      white-space: pre-wrap;
    }

    .input-result .ant-input {
      width: 100%;
      padding: 0;
      border: none;
      box-shadow: none;
    }

    &.loading {
      height: calc(100vh - 300px);

      .result-box {
        justify-content: center;
      }
    }
  }

  .result-action-box {
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 24px;
  }

  .note-feature {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: $sub-black-color;
    margin-top: 8px;

    span {
      color: $danger-color;
    }
  }

  .wrapper-request-loading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    text-align: center;
    margin-bottom: 14px;
  }

  .loading-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin-bottom: 22px;
  }

  .wrapper-success {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .success-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: center;
      color: #061d3f;
      margin-bottom: 14px;
    }

    .success-content {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: center;
    }

    > img {
      width: 37.5px;
      height: 37.5px;
      margin-top: 28px;
      margin-bottom: 30px;
    }
  }

  .status-verified {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: left;
      color: #3aa754;

      &.fail {
        color: $danger-color;
      }
    }
  }

  @include respond-to('max-large') {
    .wrapper-image-info {
      flex-direction: column;
    }
  }

  @include respond-to('max-min') {
    padding-top: 50px;

    .scanner-body {
      min-height: auto;
      flex-direction: column;
      padding: 15px;
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 24px;
    }

    .wrapper-input {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      border: none;

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
      }

      .form-upload {
        min-height: auto;
      }
    }

    .wrapper-image-info {
      padding: 10px;

      .main-image {
        padding: 10px;
      }

      .image-box,
      .info-box {
        min-width: auto;
      }

      .field-result {
        flex-direction: column;
        gap: 0;
      }
    }
  }
}
