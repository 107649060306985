/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.about-us {
  .custom-container {
    border-radius: 20px;
    border: 1px solid #d7d7d7;
    padding: 50px;
    background-color: #fafbff;
    box-shadow: 0px 4px 48px 0px #b0b0b040;
  }

  .list-contents {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .tab-list-items {
  }

  .tab-item {
    width: 256px;
    height: 62px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 14px 16px;
    cursor: pointer;

    > img {
      width: 32px;
      height: 32px;
    }

    > .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      color: $black-color;
    }

    &.active {
      background: #f3faff;
      border-radius: 16px 0 0 16px;

      .title {
        color: #216edc;
      }
    }
  }

  .tab-contents {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    border-radius: 0px 16px 16px 0px;
    background-color: #f3faff;
    color: #4e5969;
    font-size: 20px;
    padding: 32px;
    min-height: 100%;
    line-height: 34px;

    > img {
      width: 24px;
      height: 24px;
    }

    > img.sub-icon {
      width: 122px;
      height: 122px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  @include respond-to('max-min') {
    padding: 0px 10px;

    .custom-container {
      padding: 40px 20px;
    }

    .list-contents {
      flex-direction: column;
    }

    h1.title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
      margin-bottom: 30px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 16px;
    }

    .tab-item {
      width: 100%;
      &.active {
        border-radius: 16px;
      }
    }

    .tab-contents {
      font-size: 18px;
      border-radius: 16px;
      padding: 20px;

      > img.sub-icon {
        width: 80px;
        height: 80px;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}
