/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.bintech-links {
  .bintech-banner {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    background-image: url('./../../../../assets/img/bg_big.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 30px 100px;

    .content-container {
      display: block;
      padding: 0;
      align-items: center;

      .content {
        max-width: 100%;
        width: 100%;
      }

      .title {
        width: 100%;
        white-space: break-spaces;
        padding: 0;
        font-size: 50px;
        line-height: 60.51px;
        margin-bottom: 32px;
        font-weight: 700;
        text-align: center;
      }

      .description {
        width: 100%;
        word-break: break-word;
        white-space: break-spaces;
        padding: 0;
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 24px;
        text-align: center;
      }

      @include respond-to('max-min') {
        position: relative;
        flex-direction: column;
        padding: 0 15px;

        .bg-contain {
          max-width: 100%;
        }
      }
    }

    .custom-container {
    }

    @include respond-to('max-min') {
      padding: 80px 0px 50px;

      .content-container {
        .content {
          align-items: center;

          .title {
            font-size: 26px;
            font-weight: 700;
            line-height: 31.47px;
            text-align: center;
            margin-bottom: 16px;
          }

          .description {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            margin-bottom: 24px;
          }
        }
      }

      .bg-contain {
        height: 345px;
      }
    }
  }

  .list-accounts {
    margin-top: 50px;

    .ant-card {
      height: 100%;
    }

    .link-app {
      a {
        color: #061d3f;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .password {
      margin-bottom: 20px;

      span {
        color: #061d3f;
        font-weight: 500;
      }
    }
  }

  .ant-card .ant-card-head-title {
    text-align: center;
    font-weight: bold;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #e7e7e7;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    white-space: break-spaces;
  }
}
